"use client";

import Service from "./Service";
import { memo, useEffect, useState } from "react";
import styles from "@/assets/css/pages/font.module.css";
import Button from "@/components/common/shared/Button/button";
import { documentToReactComponents } from "@/lib/utils/contentful";

// Memoize the component to prevent unnecessary re-renders
const MemoizedService = memo(Service);

const WhyGsf = ({ data }) => {
  const [active, setActive] = useState(false);
  const { title, description, usps = [] } = data?.fields || {};

  const handleShow = () => {
    const branchBox = document.getElementById("gsfDescription");
    const topElement = document.getElementById("top-alert-header");

    setActive(!active);
    let topClientHeight = 0;
    if (topElement) {
      topClientHeight = topElement.clientHeight;
    }
    const position = branchBox.getBoundingClientRect().top + window.scrollY;
    setTimeout(() => {
      window.scrollTo({
        top: position - topClientHeight, // Apply offset here
        behavior: "smooth",
      });
    }, 50);
  };

  useEffect(() => {
    const gsfDescription = document.getElementById("gsfDescription");
    const scrollHeight = gsfDescription?.scrollHeight;
    if (scrollHeight < 140) {
      readmore.style.display = "none";
    }
  }, []);

  return (
    <section className="testimonial-section bg-tertiary-white py-6 sm:py-[80px] xs:min-h-max min-h-[415px]">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <h2 className={`${styles.heading32}`}>{title}</h2>

            <div className="grid grid-cols-4 sm:gap-6 gap-[10px] xs:mt-6 mt-4">
              {usps.map?.((item) => (
                <MemoizedService
                  styles={styles}
                  key={item?.sys?.id}
                  itemPic={item?.fields?.icon}
                  heading={item?.fields?.title}
                  subHeading={item?.fields?.description}
                />
              ))}
            </div>
            {!!description && (
              <div className="flex flex-col xs:border-[3px] border-[1px] border-secondary-500 rounded-2xl p-3 xs:p-8 mt-6 test2">
                <div
                  id="gsfDescription"
                  className={`description ${active ? "show" : ""}`}
                >
                  {documentToReactComponents(description)}
                </div>
                <div className="xs:hidden flex ms-auto mt-1">
                  <Button
                    id="readmore"
                    onClick={handleShow}
                    theme="blue-bordered-btn-arrow"
                  >
                    {active ? "Read Less" : "Read More"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyGsf;
