import ImageWithFallback from "../ImageWithFallback";
import { getContentfulImgData } from "@/lib/services/contentful";

const Service = ({ styles, itemPic, heading, subHeading }) => {
  const imgMeta = getContentfulImgData(itemPic);
  return (
    <div className="xs:rounded-[14px] rounded-[8px] flex flex-col items-center bg-secondary-500 xs:px-[14px] px-[6.5px] xs:pt-6 pt-[10px] xs:pb-[22px] pb-[10px] h-full">
      <ImageWithFallback
        width={50}
        height={50}
        unoptimized
        quality={80}
        loading="lazy"
        src={imgMeta.imageUrl}
        alt={imgMeta.title || imgMeta.fileName}
        className="lg:w-[72px] xs:w-[64px] w-[56px] xs:mb-[14px] mb-[8px]"
      />
      <h3 className={`${styles.heading18XB} mb-1`}>{heading}</h3>
      <p className={`${styles.heading20SB} text-center`}>{subHeading}</p>
    </div>
  );
};

export default Service;
