"use client";

import { APIProvider } from "@vis.gl/react-google-maps";

function GoogleMapProvider({ children }) {
  return (
    <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}>
      {children}
    </APIProvider>
  );
}

export default GoogleMapProvider;
